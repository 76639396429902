// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  DefaultUrlServer: 'https://api-test.sistemis.ro/mca/',
  deployId: 'ea91e787-6358-448f-9fc8-558f021e2ac0',
  filePath: 'https://api-test.sistemis.ro/mca_file/',
  authServer: 'https://api-test.sistemis.ro/auth/',
  saasServer: 'https://api-test.sistemis.ro/saas/',
  signalR: 'https://api-test.sistemis.ro/saas_hub'
};
